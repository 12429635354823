<template>
  <div v-if="device && device.audio" class="mb-5 space-y-5">
    <div v-if="device.audio.isOutputSelectionSupported" class="flex flex-col">
      <label class="mb-2" for="id_audio_output">Output device</label>
      <Select
        v-model="selectedOutputDevice"
        id="id_audio_output"
        optionLabel="label"
        optionValue="value"
        :options="outputDevices.map((audioDevice) => ({ label: audioDevice.label, value: audioDevice.value }))"
      />
    </div>

    <div class="flex flex-col">
      <label class="mb-2" for="id_audio_input">Input device</label>
      <Select
        v-model="selectedInputDevice"
        id="id_audio_input"
        optionLabel="label"
        optionValue="value"
        :options="inputDevices.map((audioDevice) => ({ label: audioDevice.label, value: audioDevice.value }))"
      />
    </div>

    <div v-if="device.audio.isOutputSelectionSupported" class="flex flex-col">
      <label class="mb-2" for="id_ringtone_output">Ringing device</label>
      <Select
        v-model="selectedRingtoneDevice"
        id="id_ringtone_output"
        optionLabel="label"
        optionValue="value"
        :options="outputDevices.map((audioDevice) => ({ label: audioDevice.label, value: audioDevice.value }))"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Device } from '@twilio/voice-sdk'

const props = defineProps<{
  device?: Device
}>()
const inputDevices = ref<SelectOption[]>([])
const outputDevices = ref<SelectOption[]>([])
const selectedInputDevice = ref<string | null>(null)
const selectedOutputDevice = ref<string | null>(null)
const selectedRingtoneDevice = ref<string | null>(null)

const addEventListeners = () => {
  if (!props.device || !props.device.audio) return

  props.device.audio.on("deviceChange", updateDevices)
}

const updateDevices = () => {
  if (!props.device || !props.device.audio) return

  outputDevices.value = []
  inputDevices.value = []

  props.device.audio.availableOutputDevices.forEach(function (audioDevice, id) {
    if (selectedOutputDevice.value === null && id === "default") {
      selectedOutputDevice.value = id
    }

    if (selectedRingtoneDevice.value === null && id === "default") {
      selectedRingtoneDevice.value = id
    }

    outputDevices.value.push({ label: audioDevice.label, value: id })
  })

  props.device.audio.availableInputDevices.forEach(function (audioDevice, id) {
    if (selectedInputDevice.value === null && id === "default") {
      selectedInputDevice.value = id
    }

    inputDevices.value.push({ label: audioDevice.label, value: id })
  })
}

const getAudioDevices = async () => {
  await navigator.mediaDevices.getUserMedia({ audio: true })
  updateDevices()
}

onMounted(() => {
  getAudioDevices()
  addEventListeners()
})

watch(selectedOutputDevice, (newValue) => {
  if (!props.device || !props.device.audio || !newValue) return

  props.device.audio.speakerDevices.set(newValue)
})

watch(selectedInputDevice,  (newValue) => {
  if (!props.device || !props.device.audio || !newValue) return

  props.device.audio.setInputDevice(newValue)
})

watch(selectedRingtoneDevice,  (newValue) => {
  if (!props.device || !props.device.audio || !newValue) return

  props.device.audio.ringtoneDevices.set(newValue)
})
</script>
