<template>
  <Button
    :loading="darkMode === undefined"
    :icon="icon"
    severity="secondary"
    @click="darkMode = !darkMode"
  />
</template>

<script lang="ts" setup>
const darkMode = ref<boolean>()

const getCurrentMode = () => {
  darkMode.value = localStorage.getItem('dark-mode') === 'true' || (!('dark-mode' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
}

const icon = computed<string>(() => darkMode.value ? 'pi pi-moon' : 'pi pi-sun')

const setTheme = () => {
  if (darkMode.value) {
    document.querySelector('html')?.classList.add('dark')
  } else {
    document.querySelector('html')?.classList.remove('dark')
  }
  localStorage.setItem('dark-mode', String(darkMode.value))
}

onMounted(() => {
  getCurrentMode()
})

watch(() => darkMode.value, () => setTheme())
</script>
