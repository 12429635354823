<template>
  <DynamicForm
    :initial-values="{
      caller_id: callerIds.length ? callerIds[0].phone_number : undefined
    }"
    :loading="loading"
    :schema="formSchema"
    ref="form"
    @submit="handleSubmit"
  />
</template>

<script lang="ts" setup>
import parsePhoneNumber from 'libphonenumber-js'
import { StringSchema } from 'yup'
import DynamicForm from '~/components/DynamicForm.vue'

const { callerIds, quickCall } = useDialer()
const form = ref<typeof DynamicForm>()
const loading = ref<boolean>(false)

yup.addMethod<StringSchema>(yup.string, 'phoneNumber', function (errorMessage: string) {
  return this.test('test-phone-number', errorMessage, function (value) {
    const { path, createError } = this
    const phoneNumber = parsePhoneNumber(value as string)

    if (phoneNumber === undefined || !phoneNumber.isValid()) {
      return createError({ path, message: errorMessage })
    }

    return true
  })
})

const formSchema = computed(() => {
  return {
    fields: [
      {
        as: 'Select',
        label: 'Caller ID',
        name: 'caller_id',
        optionLabel: (el: CallerId) => `${formatPhoneNumber(el.phone_number)} - ${el.campaign?.name}`,
        optionValue: 'phone_number',
        options: callerIds.value,
        rules: yup.string().required().oneOf(callerIds.value.map(({ phone_number }) => phone_number)).label('Caller ID'),
      },
      {
        as: 'InputText',
        label: 'To',
        name: 'to',
        rules: yup.string().required().label('To'),
      }
    ],
    submit: {
      label: 'Dial'
    }
  }
})

const emit = defineEmits<{
  dial: [CallParameters]
}>()

const handleSubmit = async (values: DialInput) => {
  loading.value = true

  const { $api } = useNuxtApp()
  await $api<CallParameters>('/crm/calls/parameters/', {
    method: 'POST',
    body: JSON.stringify(values)
  })
    .then((response) => {
      emit('dial', response)
    })
    .catch((error) => form.value?.setErrors(error.data))
    .finally(() => loading.value = false)
}

watch(quickCall, (newValue) => {
  if (form.value && newValue) {
    if (newValue.to) form.value.setFieldValue('to', newValue.to)
    if (newValue.caller_id) form.value.setFieldValue('caller_id', newValue.caller_id)
  }
})
</script>
