<template>
  <div
    :class="{
      'bottom-0 right-24 rounded-t-md w-80': dialerPosition === 'bottom-right',
      'bottom-0 left-24 rounded-t-md w-80 lg:left-96': dialerPosition === 'bottom-left',
      'bottom-24 right-0 rounded-l-md': dialerPosition === 'right-bottom',
      'top-24 right-0 rounded-l-md': dialerPosition === 'right-top',
      'w-80': uiStatus.isOpen,
    }"
    class="fixed bg-surface-100 shadow z-40 dark:bg-surface-900 dark:shadow-surface-500 dark:shadow-md"
  >
    <Button
      :class="{
        'hidden': uiStatus.isOpen,
        'rounded-b-none': ['bottom-left', 'bottom-right'].includes(dialerPosition),
        'rounded-r-none': ['right-bottom', 'right-top'].includes(dialerPosition),
      }"
      class="block min-w-12 w-full"
      icon="pi pi-phone"
      severity="info"
      @click="uiStatus.isOpen = !uiStatus.isOpen"
    />

    <div
      :class="{
        'hidden': !uiStatus.isOpen,
      }"
    >
      <div
        class="flex justify-end"
      >
        <div class="space-x-4">
          <Button
            icon="pi pi-arrows-alt"
            link
            size="small"
            @click="onChangePosition"
          />

          <Button
            icon="pi pi-cog"
            link
            size="small"
            @click="uiStatus.isOpenConfig = !uiStatus.isOpenConfig"
          />

          <Button
            icon="pi pi-times"
            link
            size="small"
            @click="uiStatus.isOpen = !uiStatus.isOpen"
          />
        </div>
      </div>

      <Message v-if="!uiStatus.ready" :closable="false" severity="error">
        Dialer is not ready.
      </Message>

      <div class="mb-4 px-4">
        <DialerConfig v-if="uiStatus.isOpenConfig" :device="device" />

        <DialerScreen v-if="uiStatus.showScreen" :call-parameters="callParameters" />

        <DialerVolumeIndicators
          v-if="uiStatus.showVolumeIndicators"
          :volume-input="volumeInput"
          :volume-output="volumeOutput"
        />

        <DialerOutbound
          :class="{ 'hidden': !uiStatus.showOutbound }"
          @dial="handleDial"
        />

        <div class="mb-3 space-x-4 text-center">
          <Button
            :class="{
              'hidden': !uiStatus.showAcceptButton
            }"
            icon="pi pi-phone"
            rounded
            @click="buttonAcceptClick"
          />

          <Button
            :class="{
              'hidden': !uiStatus.showHangupButton
            }"
            :severity="uiStatus.isMuted ? 'warn' : 'secondary'"
            icon="pi pi-microphone"
            rounded
            @click="buttonMuteClick"
          />

          <Button
            :class="{
              'hidden': !uiStatus.showHangupButton
            }"
            icon="pi pi-times-circle"
            rounded
            severity="danger"
            @click="buttonHangupClick"
          />

          <Button
            :class="{
              'hidden': !uiStatus.showRejectButton
            }"
            icon="pi pi-times-circle"
            rounded
            severity="danger"
            @click="buttonRejectClick"
          />
        </div>

        <DialerLog :log="log" />

        <DialerKeyPad
          :class="{ 'hidden': !uiStatus.showKeyPad }"
          @sendDigit="buttonKeyPadClick"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type Button from 'primevue/button'
import { Call as DeviceCall, Device } from '@twilio/voice-sdk'
import DialerConfig from './DialerConfig.vue'
import DialerKeyPad from './DialerKeyPad.vue'
import DialerLog from './DialerLog.vue'
import DialerOutbound from './DialerOutbound.vue'
import DialerScreen from './DialerScreen.vue'
import DialerVolumeIndicators from './DialerVolumeIndicators.vue'

const { account } = useAuth()
let device: Device | undefined
let token: string | undefined
let tokenRefresher = ref<NodeJS.Timeout>()
const buttonAcceptClick = ref<() => void>(() => {})
const buttonRejectClick = ref<() => void>(() => {})
const buttonHangupClick = ref<() => void>(() => {})
const buttonKeyPadClick = ref<(digit: string) => void>(() => {})
const buttonMuteClick = ref<() => void>(() => {})
const callParameters = ref<CallParameters>()
const { currentCompany } = useCompany()
const { quickCall } = useDialer()
const log = ref<string[]>([])
const volumeInput = ref<number>(0)
const volumeOutput = ref<number>(0)

const uiStatus = reactive({
  ready: false,
  isCallOngoing: false,
  isMuted: false,
  isOpen: false,
  isOpenConfig: false,
  showOutbound: true,
  showKeyPad: false,
  showScreen: false,
  showAcceptButton: false,
  showRejectButton: false,
  showHangupButton: false,
  showVolumeIndicators: false,
})

const dialerPosition = computed<DialerPosition>({
  get() {
    return account.value?.settings?.dialer_position ?? "bottom-right"
  },

  set(value) {
    const { $api } = useNuxtApp()
    $api<Account>('/account/', {
      method: 'PATCH',
      body: JSON.stringify({
        settings: {
          dialer_position: value
        }
      }),
    })
      .then(response => account.value = response)
  }
})

onUnmounted(() => {
  if (device && device.state === Device.State.Registered) device.unregister()
  if (tokenRefresher.value) {
    clearInterval(tokenRefresher.value)
    tokenRefresher.value = undefined
  }
})

watch(currentCompany, async (newValue) => {
  if (!newValue) return
  await initializeDevice()
})

watch(quickCall, (newValue) => {
  if (!newValue) return
  if (uiStatus.isOpen) return
  uiStatus.isOpen = true
})

const acceptIncomingCall = (call: DeviceCall) => {
  if (uiStatus.isCallOngoing) {
    addToLog('Call in-progress already. Can\'t accept another call.')
    return
  }

  call.accept()

  addToLog(`Accepted incoming call`)
  uiStatus.isCallOngoing = true
  uiStatus.showOutbound = false
  uiStatus.showAcceptButton = false
  uiStatus.showRejectButton = false
  uiStatus.showHangupButton = true
  uiStatus.showKeyPad = true
  uiStatus.showVolumeIndicators = true

  bindVolumeIndicators(call)
  bindKeypad(call)
}

const addDeviceListeners = (device: Device) => {
  if (!device) return

  device.on('registered', () => {
    uiStatus.ready = true
    addToLog("Device ready to make and receive calls")
  })

  device.on('error', (error) => {
    addToLog("Device error: " + error.message)
  })

  device.on('incoming', handleIncomingCall)

  device.on('unregistered', () => {
    uiStatus.ready = false
    addToLog("Device unregistered")
  })
}

const addToLog = (message: string) => {
  console.info('[Dialer]', message)
  log.value = [message, ...log.value]
}

const bindKeypad = (call: DeviceCall) => {
  buttonKeyPadClick.value = (digit: string) => {
    call.sendDigits(digit)
  }
}

const bindVolumeIndicators = (call: DeviceCall) => {
  call.on("volume", function (inputVolume, outputVolume) {
    volumeInput.value = inputVolume
    volumeOutput.value = outputVolume
  })
}

const handleIncomingCall = (call: DeviceCall) => {
  if (uiStatus.isCallOngoing) {
    addToLog(`Call in-progress, ignoring incoming call`)
    return
  }

  addToLog(`Incoming call from ${formatNumber(call.parameters.From)}`)
  callParameters.value = {
    Called: call.customParameters.get("Called") || "",
    CallerId: call.customParameters.get("CallerId") || "",
    CompanyId: call.customParameters.get("CompanyId") || "",
    CompanyName: call.customParameters.get("CompanyName") || "",
    CampaignId: call.customParameters.get("CampaignId") || "",
    CampaignName: call.customParameters.get("CampaignName") || "",
    LeadId: call.customParameters.get("LeadId"),
    LeadName: call.customParameters.get("LeadName"),
  }

  buttonAcceptClick.value = () => {
    acceptIncomingCall(call)
  }

  buttonRejectClick.value = () => {
    rejectIncomingCall(call)
  }

  buttonHangupClick.value = () => {
    hangupIncomingCall(call)
  }

  buttonMuteClick.value = () => {
    muteCurrentCall(call)
  }

  uiStatus.isOpen = true
  uiStatus.showOutbound = false
  uiStatus.showScreen = true
  uiStatus.showAcceptButton = true
  uiStatus.showRejectButton = true
  uiStatus.showHangupButton = false

  call.on('accept', acceptIncomingCall)
  call.on('cancel', handleDisconnectedIncomingCall)
  call.on('disconnect', handleDisconnectedIncomingCall)
  call.on('muted', handleMuteToggle)
  call.on('reject', handleDisconnectedIncomingCall)
}

const handleDisconnectedIncomingCall = () => {
  addToLog("Incoming call ended.")
  resetUI()
}

const handleMuteToggle = (isMuted: boolean) => {
  addToLog(`Mute toggled: ${isMuted}`)
  uiStatus.isMuted = isMuted
}

const initializeDevice = async () => {
  addToLog("Initializing device")

  if (tokenRefresher.value) {
    clearInterval(tokenRefresher.value)
    tokenRefresher.value = undefined
  }

  try {
    token = await getToken()
  } catch (e) {
    token = undefined
  }

  if (!token) {
    addToLog("Unable to initialize, token not available.")
    if (device) {
      await device.unregister()
      device = undefined
    }
    return
  }

  device = new Device(token, {
    closeProtection: true,
  })

  addDeviceListeners(device)
  setupTokenRefresh()
  await device.register()
}

const getToken = async (): Promise<string|undefined> => {
  const { $api } = useNuxtApp()
  return await $api<AccessToken>('/account/access-token/')
    .then(response => response.access_token)
}

const handleDial = async (params: CallParameters) => {
  if (!device) return

  try {
    callParameters.value = params
    const call = await device.connect({
      params: {
        To: params.Called,
        CallerId: params.CallerId
      }
    })

    addToLog(`Dialing ${formatPhoneNumber(params.Called)} from ${formatPhoneNumber(params.CallerId)}`)

    call.on("accept", updateUIAcceptedOutgoingCall)
    call.on("disconnect", resetUI)
    call.on("cancel", resetUI)
    call.on("mute", handleMuteToggle)

    buttonHangupClick.value = () => {
      addToLog("Hanging up...")
      call.disconnect()
    }

    buttonMuteClick.value = () => {
      muteCurrentCall(call)
    }

  } catch (error) {
    addToLog(`Error: ${error}`)
  }
}

const hangupIncomingCall = (call: DeviceCall) => {
  call.disconnect()
  addToLog("Hanging up incoming call")
  resetUI()
}

const muteCurrentCall = (call: DeviceCall) => {
  call.mute(!uiStatus.isMuted)
}

const rejectIncomingCall = (call: DeviceCall) => {
  call.reject()
  addToLog("Rejected incoming call")
  resetUI()
}

const resetUI = () => {
  uiStatus.isCallOngoing = false
  uiStatus.isMuted = false
  uiStatus.showOutbound = true
  uiStatus.showAcceptButton = false
  uiStatus.showRejectButton = false
  uiStatus.showHangupButton = false
  uiStatus.showKeyPad = false
  uiStatus.showScreen = false
  uiStatus.showVolumeIndicators = false

  callParameters.value = undefined
}

const setupTokenRefresh = () => {
  const timeToLive = 3600000 // 60 minutes
  const refreshBuffer = 300000 // 5 minutes

  tokenRefresher.value = setInterval(async () => {
    try {
      token = await getToken()
    } catch (e) {
      clearInterval(tokenRefresher.value)
      tokenRefresher.value = undefined
    }

    if (!token || !device) return

    addToLog("Refreshing token...")

    device.updateToken(token)
  }, timeToLive - refreshBuffer)
}

const updateUIAcceptedOutgoingCall = (call: DeviceCall) => {
  uiStatus.isCallOngoing = true
  uiStatus.showOutbound = false
  uiStatus.showAcceptButton = false
  uiStatus.showRejectButton = false
  uiStatus.showHangupButton = true
  uiStatus.showScreen = true
  uiStatus.showKeyPad = true
  uiStatus.showVolumeIndicators = true

  bindVolumeIndicators(call)
  bindKeypad(call)
}

const onChangePosition = () => {
  if (dialerPosition.value === "bottom-right") {
    dialerPosition.value = "bottom-left"
  }

  if (dialerPosition.value === "bottom-left") {
    dialerPosition.value = "right-top"
  }

  if (dialerPosition.value === "right-top") {
    dialerPosition.value = "right-bottom"
  }

  if (dialerPosition.value === "right-bottom") {
    dialerPosition.value = "bottom-right"
  }
}
</script>
