<template>
  <Button
    icon="pi pi-bell"
     severity="secondary"
    @click="togglePopover"
  />

  <Popover ref="op">
    <div class="space-y-3">
      <div class="flex items-center justify-between">
        <div class="font-bold text-xl">Notifications</div>

        <Button
          link
          rounded
          icon="pi pi-ellipsis-h"
          severity="secondary"
          @click="toggleOptions"
        />

        <Menu
          ref="optionsMenu"
          :model="[
            {
              icon: 'pi pi-check',
              label: 'Mark all as read',
              command: () => markReadAll()
            }
          ]"
          :popup="true"
        />
      </div>

      <div>
        <SelectButton
          v-model="unread"
          :options="[
            {label: 'All', value: false},
            {label: 'Unread', value: true},
          ]"
          optionLabel="label"
          optionValue="value"
          aria-labelledby="basic"
        />
      </div>

      <DataView
        :alwaysShowPaginator="false"
        :loading="status === 'pending'"
        :rows="pagination.per_page"
        :totalRecords="objectList?.count"
        :value="objectList?.results"
        class="border-none"
        dataKey="id"
        lazy
        paginator
        @page="(event) => { pagination = getPagination(event) }"
      >
        <template #list="{ items }">
          <NotificationCard
            v-for="item in items as NotificationInstance[]"
            :key="item.id"
            :notification="item"
            @delete="refresh"
          />
        </template>
      </DataView>
    </div>
  </Popover>
</template>

<script lang="ts" setup>
import type Menu from 'primevue/menu'
import type Popover from 'primevue/popover'
import NotificationCard from '~/components/cards/NotificationCard.vue'

const { currentCompany } = useCompany()
const op = ref<typeof Popover>()
const optionsMenu = ref<typeof Menu>()
const unread = ref<boolean>(false)

const pagination = ref<PaginationInput>({
  page: 1,
  per_page: 10,
})

const fetchQuery = computed(() => ({ ...pagination.value, unread: unread.value }))

const { data: objectList, refresh, status } = useApi<Paginated<NotificationInstance>, ApiError>('/notifications/', {
  query: fetchQuery,
  watch: [currentCompany, pagination, unread]
})

const markReadAll = async () => {
  const { $api } = useNuxtApp()
  await $api(`/notifications/mark-read/`, {
    method: 'POST'
  })
    .then(() => refresh())
}

const toggleOptions = (event: Event) => {
  optionsMenu.value?.toggle(event)
}

const togglePopover = (event: Event) => {
  op.value?.toggle(event)
}
</script>
