<template>
  <div class="space-y-5 text-left text-sm w-full">
    <div>
      <div>Mic Volume</div>
      <div
        :class="{
          'bg-green-500': props.volumeInput < 0.5,
          'bg-yellow-500': props.volumeInput < 0.75 && props.volumeInput >= 0.5,
          'bg-red-500': props.volumeInput >= 0.75
        }"
        class="block h-2"
        :style="{
          width: `${Math.floor(props.volumeInput * 288)}px`
        }"
      />
    </div>
    <div>
      <div>Speaker Volume</div>
      <div
      :class="{
          'bg-green-500': props.volumeOutput < 0.5,
          'bg-yellow-500': props.volumeOutput < 0.75 && props.volumeOutput >= 0.5,
          'bg-red-500': props.volumeOutput >= 0.75
        }"
        class="block h-2"
        :style="{
          width: `${Math.floor(props.volumeOutput * 288)}px`
        }"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  volumeInput: number,
  volumeOutput: number
}>()
</script>
