<template>
  <div class="bg-surface-900 flex-col-reverse max-h-36 mb-5 overflow-y-auto p-4 rounded-md space-y-1.5 text-neutral-content text-sm text-white dark:bg-surface-100 dark:text-surface-900">
    <div v-for="line in props.log">{{ line }}</div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  log: string[]
}>()
</script>
