<template>
  <div class="gap-1 grid grid-cols-3 my-5">
    <Button
      v-for="digit in digits"
      :key="digit"
      :label="digit"
      outlined
      @click="emit('sendDigit', digit)"
    />
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits<{
  sendDigit: [digit: string]
}>()
const digits = [
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '0', '#'
]
</script>
