<template>
  <div
    class="
      flex
      h-screen
      overflow-hidden
    "
  >
    <aside
      :class="{
        'translate-x-0': openSidebar,
      }"
      class="
        absolute
        bg-surface-900
        duration-300
        ease-linear
        flex
        flex-col
        h-screen
        overflow-y-hidden
        left-0
        top-0
        w-80
        z-10
        -translate-x-full
        lg:static
        lg:translate-x-0
      ">
      <DrawerSide @closeSidebar="openSidebar = false" />
    </aside>

    <div
      class="
        bg-surface-100
        flex
        flex-1
        flex-col
        overflow-y-auto
        overflow-x-hidden
        relative
        dark:bg-surface-700
      "
    >
      <header
        class="
          bg-surface-0
          drop-shadow
          flex
          sticky
          top-0
          w-full
          z-10
          dark:bg-surface-900
          dark:drop-shadow-none
        "
      >
        <Navbar @openSidebar="openSidebar = true" />
      </header>

      <main>
        <div class="mx-auto max-w-screen-2xl p-4 !pb-32 md:p-6 2xl:p-10">
          <slot />
        </div>
      </main>
    </div>
    <Dialer />
  </div>

  <DynamicDialog />
  <ConfirmDialog />
  <Toast />
</template>

<script lang="ts" setup>
import { useOneSignal } from '@onesignal/onesignal-vue3'
import * as Sentry from '@sentry/vue'
import Dialer from '~/components/dialer/Dialer.vue'

const { account } = useAuth()
const { currentCompany } = useCompany()
const oneSignal = useOneSignal()
const openSidebar = ref<boolean>(false)
const url = useRequestURL()

const siteName = computed<string>(() => {
  if (currentCompany.value) return currentCompany.value.name

  if (url.hostname === 'crmig.org') return "CRMIG"
  return "RhinoCalls"
})
const toast = useToast()

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${siteName.value}` : siteName.value
  },
})

onMounted(async () => {
  setTimeout(function () {
    setupOneSignal()
    setupSentry()
  }, 5000)
})

const setupOneSignal = async () => {
  if (!account.value) return

  await oneSignal.login(String(account.value?.id))

  await oneSignal.Slidedown.promptPush({
    force: true,
    forceSlidedownOverNative: true,
  })

  oneSignal.User.PushSubscription.addEventListener('change', async ({ current }) => {
    if (!current.id || !current.optedIn) return

    const { $api } = useNuxtApp()

    await $api<Account>('/account/', {
      method: 'PATCH',
      body: JSON.stringify({
        onesignal_id: current.id
      })
    })
      .then(response => account.value = response)
      .catch(error => errorsToToast(error, toast))
  })
}

const setupSentry = () => {
  if (!account.value) return

  Sentry.setUser({
    id: account.value.id,
    email: account.value.email,
  })
}
</script>
