<template>
  <div v-if="props.callParameters" class="bg-base-100 mb-5 p-4 rounded shadow text-sm">
    <div>
      <span class="font-bold mr-2">Company:</span>
      {{ props.callParameters?.CompanyName }}
    </div>

    <div v-if="props.callParameters.CampaignId">
      <span class="font-bold mr-2">Campaign:</span>
      <NuxtLink
        :to="{
          name: 'companyId-crm-campaigns-id',
          params: {
            companyId: props.callParameters.CompanyId,
            id: props.callParameters.CampaignId,
          }
        }"
        class="text-primary-600"
      >
        {{ props.callParameters.CampaignName }}
        <i class="ml-2 pi pi-arrow-right" />
      </NuxtLink>
    </div>

    <div v-if="props.callParameters.LeadId">
      <span class="font-bold mr-2">Lead:</span>
      <NuxtLink
        :to="{
          name: 'companyId-crm-leads-id',
          params: {
            companyId: props.callParameters.CompanyId,
            id: props.callParameters.LeadId,
          }
        }"
        class="text-primary-600"
      >
        {{ props.callParameters.LeadName }}
        <i class="ml-2 pi pi-arrow-right" />
      </NuxtLink>
    </div>

    <div>
      <span class="font-bold mr-2">From:</span>
      <PhoneNumber :phone-number="props.callParameters.CallerId" />
    </div>

    <div>
      <span class="font-bold mr-2">To:</span>
      <PhoneNumber :phone-number="props.callParameters.Called" />
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  callParameters?: CallParameters,
}>()
</script>
