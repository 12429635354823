<template>
  <div class="flex items-center justify-between px-4 py-6">
    <SiteLogo class="h-[36px]" dark />

    <Button
      class="lg:hidden"
      icon="pi pi-arrow-left"
      link
      @click="emit('closeSidebar')"
    />
  </div>

  <div class="duration-300 ease-linear flex flex-1 flex-col no-scrollbar overflow-y-auto">
    <nav class="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
      <Menu
        :model="navItems"
        class="border-none bg-surface-900 dark:bg-surface-900"
      />
    </nav>
  </div>

  <div class="drawer-side">
    <div class="flex flex-col min-h-full p-4 text-white">
      <div class="flex-none py-2 text-center text-xs">
        <div class="space-x-3">
          <NuxtLink
            :to="{
              name: 'docs-privacy-policy'
            }"
          >
            Privacy Policy
          </NuxtLink>

          <NuxtLink
            :to="{
              name: 'docs-terms-of-use'
            }"
          >
            Terms of Service
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MenuItem } from 'primevue/menuitem'
import type { RouteLocationRaw } from 'vue-router'

const { currentCompany } = useCompany()
const darkMode = ref<boolean>(false)
const emit = defineEmits<{
  closeSidebar: []
}>()
const { logout } = useAuth()

const navItems = computed<MenuItem[]>(() => {
  if (!currentCompany.value) return []

  return [
    {
      label: 'Dashboard',
      icon: 'pi pi-home',
      command: () => goTo({
        name: 'companyId',
        params: { companyId: currentCompany.value?.id },
      }),
    },
    {
      label: 'Menu',
      items: [
        {
          label: 'Calendar',
          icon: 'pi pi-calendar',
          command: () => goTo({
            name: 'companyId-crm-calendar',
            params: { companyId: currentCompany.value?.id },
          }),
        },
        {
          label: 'Leads',
          icon: 'pi pi-user',
          command: () => goTo({
            name: 'companyId-crm-leads',
            params: { companyId: currentCompany.value?.id },
          }),
        },
        {
          label: 'Phone Numbers',
          icon: 'pi pi-phone',
          command: () => goTo({
            name: 'companyId-crm-phone-numbers',
            params: { companyId: currentCompany.value?.id },
          }),
        },
        {
          label: 'Campaigns',
          icon: 'pi pi-compass',
          command: () => goTo({
            name: 'companyId-crm-campaigns',
            params: { companyId: currentCompany.value?.id },
          }),
        },
        {
          label: 'Calls',
          icon: 'pi pi-megaphone',
          command: () => goTo({
            name: 'companyId-crm-calls',
            params: { companyId: currentCompany.value?.id },
          }),
        },
        {
          label: 'Conversations',
          icon: 'pi pi-comments',
          command: () => goTo({
            name: 'companyId-crm-conversations',
            params: { companyId: currentCompany.value?.id },
          }),
        },
      ]
    },
    {
      label: 'Account',
      items: [
        {
          label: 'Your account',
          icon: 'pi pi-user',
          command: () => goTo({ name: 'account' }),
        },
        {
          label: 'Switch company',
          icon: 'pi pi-home',
          command: () => goTo({ name: 'index' }),
        },
        {
          label: 'Change password',
          icon: 'pi pi-key',
          command: () => goTo({ name: 'account-password' }),
        },
        {
          label: 'Logout',
          icon: 'pi pi-sign-out',
          command: () => logout(),
        },
      ]
    },
    {
      label: 'Setup',
      items: [
        {
          label: 'Configuration',
          icon: 'pi pi-cog',
          command: () => goTo({
            name: 'companyId-configuration',
            params: { companyId: currentCompany.value?.id },
          }),
        }
      ]
    },
  ]
})

const goTo = (params: RouteLocationRaw) => {
  navigateTo(params)
  emit('closeSidebar')
}
</script>

<style lang="scss" scoped>
:deep(.p-menu-item-link) {
  @apply text-surface-0;
}

:deep(.p-menu-item.p-focus .p-menu-item-content) {
  @apply bg-surface-700;
}
</style>
