<template>
  <div class="
      flex
      flex-grow
      items-center
      justify-between
      px-4
      py-4
      shadow-2
      md:px-6
      2xl:px-11
      dark:shadow-surface-100
    "
  >
    <div
      class="
        flex
        gap-2
        items-center
        sm:gap-4
        lg:hidden
      "
    >
      <button
        class="
          bg-white
          block
          border
          border-stroke
          p-1.5
          rounded
          shadow-sm
          dark:border-surface-400
          dark:bg-surface-400/10
          lg:hidden
        "
        @click="emit('openSidebar')"
      >
        <span class="relative block h-6 w-6 cursor-pointer">
          <span class="absolute block right-0 h-full w-full">
            <span class="bg-black block delay-[0] delay-300 duration-200 ease-in-out h-0.5 left-0 my-1 relative rounded-sm top-0 w-0 dark:bg-white !w-full"></span>
            <span class="bg-black block delay-150 delay-400 duration-200 ease-in-out h-0.5 left-0 my-1 relative rounded-sm top-0 w-0 dark:bg-white !w-full"></span>
            <span class="bg-black block delay-200 delay-500 duration-200 ease-in-out h-0.5 left-0 my-1 relative rounded-sm top-0 w-0 dark:bg-white !w-full"></span>
          </span>
          <span class="absolute block h-full right-0 rotate-45 w-full">
            <span class="absolute bg-black block delay-[0] delay-300 duration-200 ease-in-out h-full left-2.5 rounded-sm top-0 w-0.5 dark:bg-white !h-0"></span>
            <span class="absolute bg-black block delay-200 delay-400 duration-200 ease-in-out h-0.5 left-0 rounded-sm top-2.5 w-full dark:bg-white !h-0"></span>
          </span>
        </span>
      </button>

      <a href="/" class="block flex-shrink-0 lg:hidden">
        <SiteLogo class="h-[32px]" hideSiteName />
      </a>
    </div>

    <div></div>

    <div class="flex items-center gap-3 2xsm:gap-7 space-x-2">
      <DarkModeSelector />

      <NotificationList v-if="currentCompany" />

      <ReminderDrawer v-if="currentCompany" />

      <a
        aria-haspopup="true"
        aria-controls="account-menu"
        class="cursor-pointer flex gap-2 items-center"
        @click="accountMenu?.toggle"
      >
        <span class="hidden text-right lg:block">
          <span class="block text-sm font-medium text-black dark:text-white">{{ `${account?.first_name} ${account?.last_name}` }}</span>
          <span class="block text-xs font-medium text-slate-400">{{ currentCompany?.name }}</span>
        </span>
        <Avatar
          icon="pi pi-user"
          size="large"
          shape="circle"
          class="!bg-primary-500 text-white"
        />
        <i class="hidden sm:block pi pi-angle-down text-slate-400" />
      </a>
      <Menu ref="accountMenu" :model="accountMenuItems" id="account-menu" popup />
    </div>
  </div>
</template>

<script lang="ts" setup>
import Menu from 'primevue/menu'
import type { MenuItem } from 'primevue/menuitem'
import NotificationList from '~/components/lists/NotificationList.vue'
import ReminderDrawer from '~/components/layout/ReminderDrawer.vue'

const { account } = useAuth()
const { currentCompany } = useCompany()
const emit = defineEmits<{
  openSidebar: [],
}>()
const { logout } = useAuth()
const accountMenu = ref<Menu>()
const accountMenuItems: MenuItem[] = [
  {
    label: 'Your account',
    icon: 'pi pi-user',
    command: () => navigateTo({ name: 'account' }),
  },
  {
    label: 'Switch company',
    icon: 'pi pi-home',
    command: () => navigateTo({ name: 'index' }),
  },
  {
    label: 'Change password',
    icon: 'pi pi-key',
    command: () => navigateTo({ name: 'account-password' }),
  },
  {
    label: 'Logout',
    icon: 'pi pi-sign-out',
    command: () => logout(),
  },
]
</script>
